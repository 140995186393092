import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import CryptoStop from "../../Assets/Projects/CryptoStop.png";
import SnapMusic from "../../Assets/Projects/SnapMusic.png";
import editor from "../../Assets/Projects/codeEditor.png";
import MetadataModel from "../../Assets/Projects/MetadataModel.png";
import GreenView from "../../Assets/Projects/GreenView.gif";
import BooksCatalog from "../../Assets/Projects/BooksCatalog.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="green">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={GreenView}
              isBlog={false}
              title="GreenView"
              description="GreenView is a prototype feature that we developed for SnapChat. It is a feature that helps SnapChat users participate in community engagements through events and community chat to maintain environmental sustainability. Our mission is to help Gen z become more aware of environmental conscious events in their proximity."
              ghLink="https://github.com/amanuelR/GreenView"
              demoLink="https://user-images.githubusercontent.com/92605110/183267200-201242a4-7ecf-4a92-8df5-449a30d9bf61.gif"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MetadataModel}
              isBlog={false}
              title="Metadata Model"
              description="The relation scheme diagram serves as a bridge from the conceptual model that we capture in the UML class modeling language, and the relational implementation. Sadly, there is no tool that we can use that will take the relation scheme diagram and convert it directly into DDL statements that we can then submit to the database to build the tables. If there was such a tool, it would save us a great deal of time, as well as provide a quality check on the eventual physical database. In this project, I modeled the information content of the relation scheme diagram."
              ghLink="https://github.com/amanuelR/RelationSchemeMetadataModel"
              demoLink="https://github.com/amanuelR/RelationSchemeMetadataModel"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={BooksCatalog}
              isBlog={false}
              title="Books Catalog"
              description="PA Project booksCatalog - In this project created Java entities (annotated POJOs) for all the classes in the given model. Using Java GUI libraries created a UI that enables to add, update, delete and access datas from the database."
              ghLink="https://github.com/amanuelR/Books_Catalog"
              demoLink="https://github.com/amanuelR/Books_Catalog"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={CryptoStop}
              isBlog={false}
              title="Crypto$Top"
              description="Crypto$top is a cryptocurrency real-time price tracing web app built using ReactJS and CoinStats API."
              ghLink="https://github.com/amanuelR/Crypto-Stop"
              demoLink="https://github.com/amanuelR/Crypto-Stop"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SnapMusic}
              isBlog={false}
              title="SnapMusic"
              description="A website that displays music albums using Spotify API."
              ghLink="https://github.com/amanuelR/SnapMusic"
              demoLink="https://amanuelr.github.io/SnapMusic/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="WebBoard"
              description="This is a web based drawing board that I developed for my Internet programming course as a final project."
              ghLink="https://github.com/amanuelR/WebBoard"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
